"use client";
import Link from "next/link";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { REGEX } from "constants/auth";
import InputError from "../../common/misc/InputError";
import Spinner from "components/common/misc/Spinner";
import { showErrorAlert } from "components/common/alert";
import { getMessageFromErrorCode, login } from "../utils";
import { useRouter, useSearchParams } from "next/navigation";

function EmailLogin({
  onComplete,
  trans,
}: {
  onComplete: Function;
  trans: any;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const searchParams: any = useSearchParams();
  const router = useRouter();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleEmailLogin = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    setIsLoading(true);
    try {
      await login(email, password);
      router.replace(`${searchParams.get("continueUrl") ?? "/dashboard"}`);
    } catch (error: any) {
      showErrorAlert({
        title: "Login failed",
        description: getMessageFromErrorCode(error.code),
        secondaryButtonText: "Close",
      });
    } finally {
      setIsLoading(false);
      if (onComplete) {
        onComplete();
      }
    }
  };

  const onSubmit: any = (data: { email: string; password: string }) => {
    handleEmailLogin(data);
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="mt-4 space-y-4"
        method="POST"
        noValidate
      >
        <input type="hidden" name="remember" defaultValue="true" />

        {/* Start Email Input */}
        <div className="relative">
          <input
            type="email"
            id="hs-hero-signup-form-floating-input-email"
            {...register("email", {
              required: trans.common.emailRequire,
              pattern: {
                value: REGEX.EMAIL,
                message: trans.common.invalidEmail,
              },
            })}
            className="peer p-4 block w-full border-gray-200 rounded-lg text-sm placeholder:text-transparent focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600
                                    focus:pt-6
                                    focus:pb-2
                                    [&:not(:placeholder-shown)]:pt-6
                                    [&:not(:placeholder-shown)]:pb-2
                                    autofill:pt-6
                                    autofill:pb-2"
            placeholder="you@email.com"
          />
          <label
            htmlFor="hs-hero-signup-form-floating-input-email"
            className="absolute top-0 start-0 p-4 h-full text-sm truncate pointer-events-none transition ease-in-out duration-100 border border-transparent dark:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none
                                    peer-focus:text-xs
                                    peer-focus:-translate-y-1.5
                                    peer-focus:text-gray-500
                                    peer-[:not(:placeholder-shown)]:text-xs
                                    peer-[:not(:placeholder-shown)]:-translate-y-1.5
                                    peer-[:not(:placeholder-shown)]:text-gray-500"
          >
            {trans.login.yourEmail}
          </label>
          {errors.email && <InputError message={`${errors.email?.message}`} />}
        </div>
        {/* End Email  Start */}

        {/* Start Password Input */}
        <div className="relative">
          {/* Floating Input */}
          <div className="relative">
            <input
              type="password"
              id="hs-hero-signup-form-floating-input-new-password"
              {...register("password", {
                required: trans.common.passwordRequire,
              })}
              className="peer p-4 block w-full border-gray-200 rounded-lg text-sm placeholder:text-transparent focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600
                                    focus:pt-6
                                    focus:pb-2
                                    [&:not(:placeholder-shown)]:pt-6
                                    [&:not(:placeholder-shown)]:pb-2
                                    autofill:pt-6
                                    autofill:pb-2"
              placeholder="********"
            />
            <label
              htmlFor="hs-hero-signup-form-floating-input-new-password"
              className="absolute top-0 start-0 p-4 h-full text-sm truncate pointer-events-none transition ease-in-out duration-100 border border-transparent dark:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none
                                    peer-focus:text-xs
                                    peer-focus:-translate-y-1.5
                                    peer-focus:text-gray-500
                                    peer-[:not(:placeholder-shown)]:text-xs
                                    peer-[:not(:placeholder-shown)]:-translate-y-1.5
                                    peer-[:not(:placeholder-shown)]:text-gray-500"
            >
              {trans.login.yourPassword}
            </label>
            {errors.password && (
              <InputError message={`${errors.password?.message}`} />
            )}
          </div>
          {/* End Floating Input */}
        </div>
        {/* End Password Input */}

        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <input
              {...register("remeberLogin")}
              id="remember-me"
              name="remember-me"
              type="checkbox"
              defaultChecked
              className="cursor-pointer h-4 w-4 text-brand-black focus:ring-brand-black border-brand-black rounded"
            />

            <label
              htmlFor="remember-me"
              className="ml-2 block text-sm text-gray-900"
            >
              {trans.login.rememberMe}
            </label>
          </div>
          <div className="text-sm">
            <span className="cursor-pointer  text-brand-blue">
              <Link href="/auth/recovery">{trans.login.forgotPassword}</Link>
            </span>
          </div>
        </div>
        <div>
          <button
            type="submit"
            disabled={isLoading}
            className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
          >
            {isLoading ? (
              <Spinner />
            ) : (
              <>
                {trans.login.signIn}
                <svg
                  aria-hidden="true"
                  className="ml-2 -mr-1 w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </>
            )}
          </button>

          <div className="text-sm mt-4">
            {trans.login.noAccountText + " "}
            <span className="cursor-pointer  text-brand-blue">
              <Link href={`/signup`}>{trans.login.createOne}</Link>
            </span>
          </div>
        </div>
      </form>
    </>
  );
}

EmailLogin.propTypes = {
  onComplete: PropTypes.func.isRequired,
};

export default EmailLogin;
