"use client";
import React from "react";
import EmailLogin from "./EmailLogin";
import HrDeviderWithText from "components/common/misc/HrDeviderWithText";
import LogoWithTitle from "components/common/misc/LogoWithTitle";
import Social from "../social";
import { AlertComponent } from "components/common/alert";

function Login({ trans }: { trans: any }) {
  return (
    <>
      <AlertComponent />
      <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-2">
          <LogoWithTitle title={trans.login.heading} />
          <div className="px-4 max-w-md rounded-md sm:px-6 lg:px-8">
            <Social trans={trans} />
            <HrDeviderWithText text={trans.common.or} />
            <EmailLogin onComplete={() => {}} trans={trans} />
            <div className="mt-6 text-sm font-regular text-blue-600 dark:text-blue-500 hover:underline">
              <a href="/">{trans.common.backToHomePage}</a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
