import React from "react";
import PropTypes from "prop-types";

function HrDeviderWithText({ text }: { text: string }) {
  return (
    <div className="inline-flex justify-center items-center w-full">
      <hr className="my-8 w-64 h-px bg-gray-200 border-0 dark:bg-gray-700" />
      <span className="absolute left-1/2 px-3  text-gray-900 bg-white -translate-x-1/2 dark:text-white dark:bg-gray-900">
        {text}
      </span>
    </div>
  );
}

HrDeviderWithText.propTypes = {
  text: PropTypes.string.isRequired,
};

export default HrDeviderWithText;
