import React from "react";
import PropTypes from "prop-types";
import { getImageURL } from "common/utils";
import Image from "next/image";

export default function LogoWithTitle({
  title,
  message,
}: {
  title: string;
  message?: string;
}) {
  return (
    <div>
      <div className="flex justify-center">
        <a href="/">
          <Image
            src={getImageURL("/brand/logox256.png")}
            alt="EnhanceTube"
            width={40}
            height={40}
            priority
          />
        </a>
      </div>
      <h2 className=" mt-4 text-center text-xl font-semibold text-gray-900">
        {title && title}
      </h2>
      <p className=" px-4 max-w-md mt-6 sm:px-6 lg:px-8">
        {message && message}
      </p>
    </div>
  );
}

LogoWithTitle.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
};
